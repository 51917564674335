import React from "react"
import Helmet from "react-helmet"
import SiteConfig from '../../config/SiteConfig.json';


class SEO extends React.Component {
  render() {
    const { 
      postTitle, 
      postDescription,
      postImage,
      postURL,
      postLanguage
    } = this.props;

    let title;
    let description;
    let image;
    let url;
    let language;

    const config = SiteConfig.seo;

    postTitle ? title = postTitle : title = config.siteTitle;
    postDescription ? description = postDescription : description = config.siteDescription;
    postImage ? image = `${SiteConfig.url}${postImage}` : image = config.siteImage;
    postURL ? url = `${SiteConfig.url}${postURL}` : url = SiteConfig.url;
    postLanguage ? language = postLanguage : language = 'en';

    const schemaOrgJSONLD = {
        "@context": "http://schema.org",
        "@type": "Organization",
        url: SiteConfig.url,
        name: config.siteName,
        alternateName: config.siteTitle,
        headline: config.siteName,
        description,
        sameAs: config.facebook
    }
    return (
      <Helmet>
        <html lang={language || 'en'} />
        <title>{title || 'Help Center'}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta name="keywords" content={config.keyWords} />
        <meta http-equiv="content-language" content={language} />
        <meta name="theme-color" content={config.themeColor} />
        <link rel="icon" type="image/png" href={config.favicon} />
        <link rel="canonical" href={SiteConfig.url} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:site_name" content={config.siteName} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={config.siteFBAppID}/>
        <meta property="og:locale" content="en_US" />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={`@${SiteConfig.brand}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content={`@${SiteConfig.brand}`} />
      
      </Helmet>
    );
  }
}

export default SEO;